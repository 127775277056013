// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import '~bootstrap/dist/css/bootstrap.min.css';

/* Importing Datepicker SCSS file. */
// @import "ngx-bootstrap/datepicker/bs-datepicker";
@import "~node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";



// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$oak-insights-primary: mat.define-palette(mat.$indigo-palette);
$oak-insights-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$oak-insights-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$oak-insights-theme: mat.define-light-theme((color: (primary: $oak-insights-primary,
        accent: $oak-insights-accent,
        warn: $oak-insights-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($oak-insights-theme);

.background-theme {
  background-color: #e5f2fb;
}

// html, body { height: 100%; }
// * { margin: 0; font-family: Avenir, 'Roman', Bold; }

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Avenir-Roman' !important
}

@font-face {
  font-family: Gilroy-Bold;
  src: url(assets/Font/Avenir/gilroy-extrabold-webfont.woff2) format("woff2");
}

@font-face {
  font-family: Avenir-Roman;
  src: url(assets/Font/Avenir/Avenir-Roman.woff2) format("woff2");
}

@font-face {
  font-family: Gilroy-Light;
  src: url(assets/Font/Avenir/gilroy-light-webfont.woff2) format("woff2");
}

:root {
  --color1: #465da4;
  --color2: #F3837A;
  --color3: #364096;
  --color4: #F3A7AB;
  --color5: #70c49c;
  --color6: #F8A992;
  --color7: #FCC294;
  --color8: #4164AD;
  --color9: #444444;
  --color10: #6b6b6b;
  --color11: #bababb;
  --color12: #eeeeee;
  --heatmap-color1: #63c5bf;
  --heatmap-color2: #82c67c;
  --heatmap-color3: #f2c853;
  --heatmap-color4: #ecab5c;
  --heatmap-color5: #ee6259;
  --heatmap-color6: #daf0ff;
  --heatmap-color7: #f04237;
  --heatmap-color8: #a1c67c;


  --fontRegular: 'Avenir-Roman';
  --fontBold: 'Gilroy-Bold';
  --fontLight: 'Gilroy-Light';

}

.height-60 {
  height: 60%;
}

.highcharts-credits {
  visibility: hidden;
}

.app-container {
  margin-left: 1rem;
  margin-right: 1rem;

}

.bs-datepicker-body table td.week span {
  color: var(--color3) !important;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover {
  background-color: var(--color3) !important;
}

@media only screen and (min-width: 992px) {
  bs-datepicker-container {
    left: 10px !important;
  }
}

@media only screen and (min-width: 1200px) {
  bs-datepicker-container {
    left: 0 !important;
  }
}

//bs-datepicker-container{ transform: scale(1,1) !important; top: 118px !important; left: 200 !important; right: 0 !important; width: fit-content !important; margin-left: auto !important; margin-right: auto !important; } 

.app-container .owl-next,
.app-container .owl-prev {
  color: black !important;
  background-color: unset !important;
  height: 60px !important;
  width: 60px !important;
  position: absolute;
  top: 40%
}

.app-container .owl-next {
  right: -72px;
  transform: translate(-50%, -50%);
}

.app-container .owl-prev {
  left: -12px;
  transform: translate(-50%, -50%);
}

.app-container .owl-next i,
.app-container .owl-prev i {
  font-size: 24px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

}

.card-heading {
  font: var(--fontBold);
  font-size: 1.5rem;
  font-weight: 800;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.widget-subtitle {
  font-size: 1rem;
  color: gray;
  margin-bottom: 2rem;
}

.open-closed {
  margin-bottom: 1rem;
}

.open-days-button {
  background-image: linear-gradient(to right,
      var(--color3) 0%,
      var(--color5) 51%,
      var(--color3) 100%);
  padding: 0.5vw 1.65vw;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-width: 0;
  border-radius: 100px;
  border-color: #ffffff;
  font-weight: 800;
  font-size: 0.75rem;
  letter-spacing: 1px;
  margin: 0.6rem 1rem;
}

.open-days-button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.closed-days-button {
  background-image: linear-gradient(to right,
      var(--color11) 0%,
      var(--color12) 51%,
      var(--color11) 100%);
  padding: 0.5vw 1.65vw;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: black;
  border-width: 0;
  border-radius: 100px;
  border-color: #ffffff;
  font-weight: 800;
  font-size: 0.75rem;
  letter-spacing: 1px;
  margin: 0.6rem 1rem;
}

.closed-days-button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #000;
  text-decoration: none;
}

.cursor {
  cursor: pointer;
}


.heightfull {
  height: 100%;
}

.withfull {
  width: 100%;
}

.text-color {
  color: var(--color8) !important;

}

.aligncenter {
  align-items: center;
}

.custom-dialog-container .mat-dialog-container {
  margin: 0px !important;
  padding: 0px !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-40 {
  width: 40% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-100 {
  width: 100% !important;
}